import React, { useEffect } from 'react'
import { PDFContainer } from './styled'
import { Grid, Row, Col } from 'atomic'

import logo from './../header/assets/logo.svg'

const PDF = props => {
  useEffect(() => {
    if (Object.keys(props.pdfData).length > 0) {
      window.print()
    }
  }, [props.pdfData])

  return (
    <PDFContainer>
      <Grid>
        <Row>
          <Col xs={12} className="col-100">
            <img src={logo} alt="logo" className="logo" />
          </Col>
        </Row>

        <Row className="ctn-info">
          <Col xs={12} className="col-100">
            <p>
              <strong>Médico: </strong> {props.pdfData.nomeMedico}
            </p>
            <p>
              <strong>CRM: </strong> {props.pdfData.crm}
            </p>

            <div className="dotted-line" />
          </Col>

          <Col xs={12} className="col-100">
            <p>
              <strong>Paciente: </strong> {props.pdfData.nomePaciente}
            </p>
            <p>
              <strong>Data de Nascimento: </strong> {props.pdfData.dataNascimento}
            </p>
          </Col>
        </Row>

        <Row className="ctn-dados-fixos">
          <Col xs={12} className="col-dados-fixos col-100">
            <p className="subtitle">
              <strong>Dados Fixos: </strong>
            </p>
            <p>Valem para todo o gráfico</p>
          </Col>
          <Col xs={6} className="col-dados-fixos col-50">
            <p>
              <strong>Peso do paciente: </strong>
            </p>
            <p>{props.pdfData.peso} kg</p>
          </Col>
          <Col xs={6} className="col-dados-fixos col-50">
            <p>
              <strong>ASC alvo: </strong>
            </p>
            <p>{props.pdfData.AUC_alvo} µmol/L*min</p>
          </Col>
          <Col xs={6} className="col-dados-fixos col-50">
            <p>
              <strong>Dose básica administrada: </strong>
            </p>
            <p>{props.pdfData.dose_inicial} mg</p>
          </Col>
          <Col xs={6} className="col-dados-fixos col-50">
            <p>
              <strong>Intervalo entre doses: </strong>
            </p>
            <p>24h em 24h, via endovenosa</p>
          </Col>
        </Row>
      </Grid>

      <p className="divisao-pagina">Gráfico e resultados na próxima página</p>

      <Grid>
        <Row>
          <Col xs={12} className="col-dados-fixos col-100 next-page">
            <p className="subtitle">
              <strong>Tempo (min) vs. Concentração (µg/mL) </strong>
            </p>
            <p>
              <strong>Paciente: </strong> {props.pdfData.nomePaciente}
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={12} className="col-chart col-100">
            <p className="title-chart">Área sob a curva: {props.pdfData.AUC_total} µmol/L*min</p>
            <div className="chart-legend">
              <p className="vertical">Concentração (µg/mL)</p>
              <img src={props.chartImage} alt="chart" />
            </div>
            <p className="horizontal">Tempo (min)</p>
          </Col>
        </Row>

        <Row>
          <Col xs={12} className="resumo col-100">
            <p className="subtitle">
              <strong>Resumo dos resultados </strong>
            </p>
            <p>
              ASC alvo: <strong>{props.pdfData.AUC_alvo} µmol/L*min</strong> em 24h. A nova dose
              será de:
            </p>

            <p className="highlight">{props.pdfData.proxima_aplicacao} mg em 24h</p>
          </Col>
          <Col xs={12} className="resumo col-100">
            <p>
              <strong>Constante de eliminação </strong>
            </p>
            <p>{props.pdfData.constante_eliminacao}</p>
          </Col>
          <Col xs={2} className="resumo col-20">
            <p>
              <strong>Meia vida</strong>
            </p>
            <p>{props.pdfData.tempo_de_vida_horas} h</p>
          </Col>
          <Col xs={2} className="resumo col-20">
            <p>
              <strong>Dose por quilo </strong>
            </p>
            <p>{props.pdfData.dose_por_quilo} mg/kg</p>
          </Col>
        </Row>
      </Grid>
    </PDFContainer>
  )
}

PDF.defaultProps = {}

export default PDF
