import styled from 'styled-components'

export const HeaderContainer = styled.div`
  position: fixed;
  top: 0;

  width: 100%;
  height: 72px;

  background: white;

  display: flex;

  justify-conteny: center;
  align-items: center;

  z-index: 999;

  -webkit-box-shadow: -2px 8px 5px -5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: -2px 8px 5px -5px rgba(0, 0, 0, 0.25);
  box-shadow: -2px 8px 5px -5px rgba(0, 0, 0, 0.25);

  img {
    // height: 100%;
    display: block;
    width: 100%;
    margin: auto;
    max-width: 362px;
  }

  h1 {
    padding: 0;
    margin: auto;
  }
  span {
    position: absolute;
    text-indent: -9999px;
  }

  @media (max-width: 1200px) {
    img {
      width: 80%;
    }
  }

  @media print {
    display: none;
  }
`
