import styled from 'styled-components'
import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  body {
   background: white;
  }

  button,p,a,div,span,input {
    font-family: ASAP,Verdana;
  }

  @media print {

    .cc-window,#footer  {
      display:none !important;
    }
  }

`

export const CalculadoraContainer = styled.div`
  h1,
  h2,
  h3,
  h4.h5,
  p,
  span,
  a {
    color: ##77797b;
  }

  width: 100%;
  overflow: hidden;

  background: white;
  padding-bottom: 40px;

  button {
    width: 100%;
    height: 40px;
    border-radius: 19px;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    cursor: pointer;
    outline: none;
    &.btn-outlined {
      background: white;
      border: 2px solid #ed0f69;
      color: #ed0f69;
    }

    &.btn-pink {
      background: #ed0f69;
      border: none;
      color: white;
    }

    &:disabled {
      cursor: not-allowed;
      background: #efefef;
      color: #b9b9b9;
      border: 2px solid #b9b9b9;
    }
  }
`

export const WrapperEfeito = styled.div`
  width: 100%;
  position: relative;

  height: 120px;
  .efeito {
    position: absolute;
    right: 0;
    top: -30px;
  }

  @media (max-width: 1200px) {
    display: none;
  }
`
