import React, { useState, useRef } from 'react'

import { InputContainer } from './styled'

import InputMask from 'react-input-mask'

const Input = props => {
  const [value, setValue] = useState('')

  const input = useRef()

  const onChange = e => {
    let value = e.target.value

    if (props.type === 'tel') {
      const regex = /^\([0-9]{2}(?:\))\s?[0-9]{5}(?:-)[[0-9]{3}|[0-9]{4}]$/gm

      if (regex.test(value)) {
        input.current.setCustomValidity('')
      } else {
        input.current.setCustomValidity('Digite um telefone válido.')
      }
    }

    if (props.regex) {
      if (props.regex.test(value)) {
        input.current.setCustomValidity('')
      } else {
        input.current.setCustomValidity('Preencha com um valor válido.')
      }
    }

    setValue(value)

    props.onChangeValue(value)
  }

  return (
    <InputContainer
      className="inputContainer"
      borderColor={props.borderColor}
      borderHighlightColor={props.borderHighlightColor}
    >
      {props.mask ? (
        <InputMask mask={props.mask} onChange={onChange} maskPlaceholder="">
          <input
            ref={input}
            type={props.type}
            required={props.required}
            minLength={props.minLength}
            className={`${value ? 'filled' : ''}`}
            name={props.name}
            placeholder={props.placeholder}
          />
        </InputMask>
      ) : (
        <input
          type={props.type}
          onChange={onChange}
          required={props.required}
          minLength={props.minLength}
          className={`${value ? 'filled' : ''}`}
          name={props.name}
          placeholder={props.placeholder}
        />
      )}

      {props.effectFocus && props.label && (
        <label className={`${value ? 'animated' : ''}`}>{props.label}</label>
      )}
    </InputContainer>
  )
}

Input.defaultProps = {
  type: 'text',
  label: null,
  onChangeValue: () => {},
  mask: null,
  required: false,
  minLength: 0,
  name: '',
  placeholder: '',
  effectFocus: true,
  borderColor: '#464646',
  borderHighlightColor: '#008471'
}

export default Input
