import React, { useState } from 'react'
import { ResultsContainer } from './styled'
import { Grid, Row, Col } from 'atomic'
import Input from 'site/src/components/landing-design-system/input'
import { FormFields } from './../form/styled'

/* eslint-disable */
const regexmask = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/

const Result = props => {
  const [nomePaciente, setNomePaciente] = useState('')
  const [dataNascimento, setDataNascimento] = useState('')
  const [nomeMedico, setNomeMedico] = useState('')
  const [crm, setCrm] = useState('')
  const [ciente, setCiente] = useState(false)

  const disablePDf = nomePaciente && dataNascimento && nomeMedico && crm && ciente

  // const print = () => {

  // }

  const onDownloadPdf = () => {
    const allData = {
      ...props.result,
      nomePaciente,
      dataNascimento,
      nomeMedico,
      crm,
      ciente
    }

    props.onDownloadPdf(allData)
  }
  return (
    <>
      {Object.keys(props.result).length > 0 && (
        <ResultsContainer id="result">
          <Grid>
            <Row className="ctn-results">
              <Col xs={12} sm={12} md={10} lg={8} c>
                <p className="title">Resumo dos resultados</p>
              </Col>
            </Row>

            <Row className="ctn-results">
              <Col xs={12} sm={12} md={10} lg={6} className="col-results">
                <p>
                  ASC alvo: <strong>{props.result.AUC_alvo} µmol/L*min</strong> em{' '}
                  {props.result.intervalo_entre_doses}h. A nova dose será de:
                </p>
                <p className="pink-highlight">
                  {props.result.proxima_aplicacao} mg de {props.result.intervalo_entre_doses}h em{' '}
                  {props.result.intervalo_entre_doses}h
                </p>

                <hr />
                <p>
                  Constante de eliminação: <strong>{props.result.constante_eliminacao}</strong>
                  <br /> Meia vida: <strong>{props.result.tempo_de_vida_horas} h</strong>
                  <br /> Dose por quilo: <strong>{props.result.dose_por_quilo} mg/kg</strong>
                </p>
                <hr />
                <p>Para gerar um PDF com os resultados acima, preencha as informações abaixo:</p>

                <br />
                <br />
                <p>
                  <strong>Dados do paciente</strong>
                </p>
                <br />
                <br />

                <FormFields className="fields-result">
                  <Row className="fields">
                    <Col xs={12} sm={12} md={12} lg={12} className="col-fields">
                      <label>Nome completo</label>
                      <Input
                        required
                        placeholder="ex.: João da Silva"
                        minLength={2}
                        name="nome_paciente"
                        effectFocus={false}
                        borderColor="#B9B9B9"
                        borderHighlightColor="#ED0F69"
                        onChangeValue={value => {
                          if (value.length > 2) {
                            setNomePaciente(value)
                          } else {
                            setNomePaciente('')
                          }
                        }}
                      />
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={12} className="col-fields">
                      <label>Data de nascimento</label>
                      <Input
                        required
                        placeholder="ex.: DD/MM/AAAA"
                        name="data_nascimento_paciente"
                        regex={regexmask}
                        mask="99/99/9999"
                        effectFocus={false}
                        borderColor="#B9B9B9"
                        borderHighlightColor="#ED0F69"
                        onChangeValue={value => {
                          if (regexmask.test(value)) {
                            setDataNascimento(value)
                          } else {
                            setDataNascimento('')
                          }
                        }}
                      />
                    </Col>
                  </Row>
                </FormFields>

                <p>
                  <strong>Dados do Médico</strong>
                </p>
                <br />
                <br />

                <FormFields className="fields-result">
                  <Row className="fields">
                    <Col xs={12} sm={12} md={12} lg={12} className="col-fields">
                      <label>Nome completo</label>
                      <Input
                        required
                        placeholder="ex.: João da Silva"
                        minLength={2}
                        name="nome_medico"
                        effectFocus={false}
                        borderColor="#B9B9B9"
                        borderHighlightColor="#ED0F69"
                        onChangeValue={value => {
                          if (value.length > 2) {
                            setNomeMedico(value)
                          } else {
                            setNomeMedico('')
                          }
                        }}
                      />
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={12} className="col-fields">
                      <label>CRM</label>
                      <Input
                        required
                        placeholder="ex.: 54008-SP"
                        name="crm"
                        effectFocus={false}
                        borderColor="#B9B9B9"
                        borderHighlightColor="#ED0F69"
                        onChangeValue={value => {
                          setCrm(value)
                        }}
                      />
                    </Col>
                  </Row>
                </FormFields>

                <FormFields className="fields-result">
                  <Row className="fields">
                    <Col xs={12} sm={12} md={12} lg={12} className="col-fields field-checkbox">
                      <div className="custom-checkbox">
                        <input
                          type="checkbox"
                          onChange={() => {
                            setCiente(!ciente)
                          }}
                        />
                        <div className="fake-check" />
                      </div>

                      <p>
                        Estou ciente e concordo que os dados aqui inseridos são de minha inteira
                        responsabilidade como médico
                      </p>
                    </Col>
                  </Row>
                </FormFields>

                <button className="btn-outlined" onClick={onDownloadPdf} disabled={!disablePDf}>
                  Baixar em PDF
                </button>
              </Col>

              <Row className="col-privacy">
                <Col xs={8} xsOffset={2}>
                  <p>
                    Quer saber mais sobre como o Fleury maneja seus dados? Acesse nosso Portal de
                    Privacidade clicando{' '}
                    <a
                      href="https://cdn.cosmicjs.com/4632fdd0-2b5a-11eb-8245-31a0d5eed227-Poltica-de-Privacidade---Grupo-Fleury.pdf"
                      target="_blank"
                    >
                      aqui
                    </a>
                  </p>
                </Col>
              </Row>
            </Row>
          </Grid>
        </ResultsContainer>
      )}
    </>
  )
}

Result.defaultProps = {
  result: null,
  onDownloadPdf: () => {}
}
export default Result
