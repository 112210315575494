import React from 'react'
import { Chart } from 'react-charts'

const Charts = props => {
  const data = React.useMemo(
    () => [
      {
        label: 'Concentração',
        showPoints: true,
        color: `#ED0F69`,
        data: [...props.data]
      }
    ],
    [props.data]
  )

  const series = React.useMemo(
    () => ({
      type: 'line'
    }),
    []
  )

  const axes = React.useMemo(
    () => [
      { primary: true, type: 'linear', position: 'bottom' },
      { type: 'linear', position: 'left' }
    ],
    []
  )

  const getSeriesStyle = React.useCallback(
    () => ({
      // transition: 'all .5s ease',
      color: `#ED0F69`
    }),
    []
  )

  const tooltip = React.useMemo(
    () => ({
      anchor: 'pointer'
    }),
    []
  )

  const lineChart = (
    <Chart
      data={data}
      axes={axes}
      series={series}
      tooltip={tooltip}
      getSeriesStyle={getSeriesStyle}
    />
  )

  return lineChart
}

Charts.defaultProps = {
  data: []
}

export default Charts
