import styled from 'styled-components'

export const PDFContainer = styled.div`
  opacity: 0;
  position: absolute;
  height: 0;
  overflow: hidden;

  p + p {
    margin-top: 5px;
  }

  .col-100 {
    width: 100%;
  }

  .col-50 {
    width: 50%;
  }

  .col-20 {
    width: 20%;
  }

  .logo {
    width: 80%;
    margin: auto;
    display: block;
  }

  .dotted-line {
    width: !00%;
    height: 1px;
    border-bottom: 1px dashed #b9b9b9;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .ctn-info {
    background: #f8f8f8 !important;
    border-radius: 16px;
    padding: 40px;
    margin-top: 20px;

    -webkit-print-color-adjust: exact;
  }

  .ctn-dados-fixos {
    margin-top: 60px;

    .col-dados-fixos {
      margin-bottom: 30px;
    }
  }

  .next-page {
    margin-top: 50px;
  }

  .subtitle {
    font-size: 20px;
  }

  .divisao-pagina {
    font-weight: bold;
    color: #ed0f69;
    font-size: 20px;
    page-break-after: always;
    text-align: center;
    margin-top: 200px;
  }

  .col-chart {
    .chart-legend {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      position: relative;
      justify-content: center;

      .vertical {
        writing-mode: vertical-rl;
        text-orientation: mixed;
        transform: rotate(180deg);
        margin-top: 20px;
        margin-bottom: 120px;
        font-weight: bold;
      }

      img {
        margin-left: 20px;
        max-width: 80%;
      }
    }

    .horizontal,
    .title-chart {
      font-weight: bold;
      text-align: center;
      margin-top: 40px;
    }
  }

  .resumo {
    margin-top: 20px;

    .highlight {
      margin-top: 20px;

      font-size: 24px;
      font-weight: bold;
      color: #ed0f69;
    }
  }

  @media print {
    opacity: 1;
    position: relative;

    height: auto;
    overflow: auto;
  }
`
