import styled from 'styled-components'
import fakecheckbg from './assets/fake-check.png'

export const FormContainer = styled.form`
  position: relative;
  width: 100%;
  margin-top: 120px;

  .row-with-legenda {
    position: relative;
    display: flex;
    flex-direction: row;

    .legenda {
      position: absolute;
      right: 170px;
      bottom: 280px;
      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
      }

      &.vertical {
        color: blue;
        transform: rotate(-90deg);

        top: 20px;
        right: 220px;
      }
    }

    .chart-row {
      height: 400px;
    }
  }

  .row-buttons {
    margin-left: 130px;
    margin-top: 200px;
  }

  .tooltip-wrap {
    color: #b9b9b9;
    div:nth-child(1) {
      div:nth-child(1) {
        div:nth-child(1) {
          background: #f8f8f8 !important;
          -webkit-box-shadow: 0px 10px 5px -9px rgba(0, 0, 0, 0.28);
          -moz-box-shadow: 0px 10px 5px -9px rgba(0, 0, 0, 0.28);
          box-shadow: 0px 10px 5px -9px rgba(0, 0, 0, 0.28);
          border: 1px solid #b9b9b9 !important;
          div:nth-child(1) {
            display: none;
          }

          div:nth-child(2) {
            div:nth-child(1) {
              display: none;
            }
          }
        }
      }
    }

    table {
      td:nth-child(1) {
        border-right: 1px solid #b9b9b9 !important;
      }
      td:nth-child(2) {
        border-left: 1px solid #b9b9b9 !important;
        border-right: 1px solid #b9b9b9 !important;
      }
    }
  }

  .message {
    margin-top: 40px;
    background: #fde7f0;
    border-radius: 15px;
    padding: 20px;
    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 14px;
      white-space: pre-wrap;
      &.title {
        font-weight: 600;
        color: #ed0f69;
      }
    }
  }

  .spiner-container {
    position: fixed;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    width: 100%;
    z-index: 10;

    .spiner {
      position: relative;
      left: 50%;
      width: 70px;
      margin-left: -20px;
      top: 40%;
    }
  }

  .subtitle-chart {
    margin-left: 30px;
    margin-bottom: 20px;
  }

  .centered-subtitle {
    text-align: center;
    margin-bottom: 20px;
    margin-left: 120px;
  }

  .ReactChart {
    margin-left: 60px;
  }

  @media (max-width: 1200px) {
    .efeito {
      display: none;
    }

    .ReactChart {
      margin-left: -10px;
    }

    .row-with-legenda {
      .legenda {
        right: 410px;
        bottom: 100px;
        &.vertical {
          right: 640px;
          top: 890px;
        }
      }
    }

    .row-buttons {
      margin-left: 0;
      button {
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 1025px) {
    .row-with-legenda {
      .legenda {
        right: 295px;
        bottom: 160px;
        &.vertical {
          right: 390px;
          top: 1280px;
        }
      }
    }
  }

  @media (max-width: 760px) {
    .row-with-legenda {
      .legenda {
        display: none;
      }
    }

    .centered-subtitle {
      margin-left: 0;
    }
  }

  @media print {
    display: none;
  }
`

export const GrayArea = styled.div`
  width: 100%;
  background: #f8f8f8;
  display: block;
  padding: 40px;
  border-radius: 16px;

  @media (max-width: 760px) {
    margin-bottom: 40px;
  }
`

export const FormFields = styled.div`

  width:100%;
  position: relative;


  label {
    display:block;
    font-weight: 600;
    font-size: 17px;
    line-height: 18px;
    margin-bottom:20px;
    color: #77787B;

    &.title {
      font-size:24px;
    }
  }

  input[type='submit'] {
    background: #008471;
    border-radius: 24px;
    width: 100%;
    height: 50px;
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    color: #f7f6f4;
    outline: none;
    border: none;
    cursor:pointer;
    font-weight:600;
  }

  input[type='text']  {
    background:none;
  }

  .fields {
    
    .col-fields {
      margin-bottom: 60px;

      &.centered {
        text-align:center;
      }
    }

    .fixed-text {
      line-height: 45px;
    }
  }

  .loading {
    display: flex;
    justify-content: center;
    
    img {
      position:relative;
      left.-10px;
      top:-10px;
      width: 60px;
    }
  }

  .button-chart {
    max-width:252px;
  }

  .custom-checkbox {
    width:15px;
    position:relative;

    input {
      cursor:pointer;

      &:checked {
        opacity:none;
  
        & + .fake-check {
        
          width:15px;
          height:15px;
          position:absolute;
          top: 3px;
          left: 4px;
          pointer-events:none;
          background: url(${fakecheckbg});
          background-size: contain;
        }
      }
    }

  }

  @media (max-width: 1200px) {
 

  }


  @media (max-width: 480px) {

  }

  @media print {
    display:none;
    
  }

`
