import styled from 'styled-components'

export const InputContainer = styled.div`
  width: 100%;
  position: relative;

  label {
    font-family: 'ASAP', Verdana;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    position: absolute;
    left: 3px;
    color: #464646;
    top: -10px;

    &:not(.animated) {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 16px;
      color: #878989;
      pointer-events: none;
      top: 10px;
      transition: all 0.1s ease-out;
    }
  }

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  textarea {
    border: none;
    border-bottom: 1px solid ${props => props.borderColor};
    width: 100%;
    height: 40px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    font-family: 'ASAP', Verdana;
    outline: none;
    color: #373a3a;
    padding: 5px;

    &:focus {
      border-bottom: 1.5px solid ${props => props.borderHighlightColor};

      + label {
        color: #464646;
        top: -10px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        transition: all 0.1s ease-out;
      }
    }

    &.filled:invalid {
      border-bottom: 1.5px solid red;
    }
  }

  textarea {
    margin-top: 10px;
    height: 100px;
    resize: none;
  }
`
