import React, { useState } from 'react'

import { SEO } from 'site/src/components/legacy/mol.seo/seo.component'

import { CalculadoraContainer, GlobalStyle } from './styled'

import Header from './header'

import Form from './form'
import Results from './results'
import PDF from './pdf'
import { Footer } from 'site/src/components/org.footer/footer.component'

export default props => {
  const [result, setResult] = useState({})
  const [pdfData, setPdfData] = useState({})

  const [chartImage, setChartImage] = useState('')
  return (
    <>
      <GlobalStyle />
      <Header />
      <SEO
        socialMedia={{
          canonicalUrl: `http://fleury.com.br/medico/calculadora`,
          title: 'Calculadora Bussulfano'
          // image: metadata.logo.url
          // imageAlt: metadata.apresentacao.titulo,
          // description: metadata.apresentacao.descricao
        }}
      />

      <CalculadoraContainer>
        <Form
          onResult={result => {
            setResult(result)
          }}
          onChartImage={image => {
            setChartImage(image)
          }}
        />
        <Results
          result={result}
          onDownloadPdf={data => {
            setPdfData(data)
          }}
        />

        <PDF pdfData={pdfData} chartImage={chartImage} />
      </CalculadoraContainer>

      <div id="footer">
        <Footer />
      </div>
    </>
  )
}
