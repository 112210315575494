import React from 'react'

import { Row, Col } from 'atomic'
import Input from 'site/src/components/landing-design-system/input'

/* eslint-disable */

const Fields = props => {
  const pesoMask = [
    /[0-9]/,
    /(\,)|([0-9])/,
    /(\,)|([0-9])/,
    /(\,)|([0-9])/,
    /(\,)|([0-9])/,
    /(\,)|([0-9])/
  ]

  const floatMask = [
    /[0-9]/,
    /(\,)|([0-9])/,
    /(\,)|([0-9])/,
    /(\,)|([0-9])/,
    /(\,)|([0-9])/,
    /(\,)|([0-9])/,
    /(\,)|([0-9])/
  ]

  return (
    <>
      <Row className="fields">
        <Col xs={12} sm={12} md={6} lg={6} className="col-fields">
          <label className="title">Dados fixos</label>
          <p>Valem para todo o gráfico</p>
        </Col>
      </Row>

      <Row className="fields">
        <Col xs={12} sm={12} md={6} lg={6} className="col-fields">
          <label>Peso do paciente (kg)</label>
          <Input
            required
            placeholder="ex.: 70 kg"
            minLength={2}
            name="peso"
            regex={/^[+-]?\d+(\,\d+)?$/}
            mask={pesoMask}
            effectFocus={false}
            borderColor="#B9B9B9"
            borderHighlightColor="#ED0F69"
          />
        </Col>

        <Col xs={12} sm={12} md={6} lg={6} className="col-fields">
          <label>ASC alvo (µmol/L*min)</label>
          <Input
            required
            placeholder="ex.: 1231 (µmol/L*min)"
            minLength={2}
            name="AUC_alvo"
            regex={/^[+-]?\d+(\,\d+)?$/}
            mask={floatMask}
            effectFocus={false}
            borderColor="#B9B9B9"
            borderHighlightColor="#ED0F69"
            onChangeValue={value => {
              props.onAUCChange(value)
            }}
          />
        </Col>
      </Row>

      <Row className="fields">
        <Col xs={12} sm={12} md={6} lg={6} className="col-fields">
          <label>Dose básica administrada (mg)</label>
          <Input
            required
            placeholder="ex.: 100 mg"
            minLength={2}
            name="dose_inicial"
            regex={/^[+-]?\d+(\,\d+)?$/}
            mask={floatMask}
            effectFocus={false}
            borderColor="#B9B9B9"
            borderHighlightColor="#ED0F69"
          />
        </Col>

        <Col xs={12} sm={12} md={6} lg={6} className="col-fields">
          <label>Intervalo entre doses</label>
          <p className="fixed-text">24/24 horas (endovenosa)</p>
        </Col>
      </Row>

      <Row className="fields">
        <Col xs={12} sm={12} md={6} lg={6} className="col-fields">
          <label className="title">Dados variáveis</label>
          <p>Valem para cada ponto do gráfico</p>
        </Col>
      </Row>

      <Row className="fields">
        <Col xs={12} sm={12} md={6} lg={6} className="col-fields">
          <label>Tempo (min)</label>
          <Input
            placeholder="ex.: 10 min (maior que zero)"
            minLength={2}
            name="tempo_min"
            mask="999"
            effectFocus={false}
            borderColor="#B9B9B9"
            borderHighlightColor="#ED0F69"
          />
        </Col>

        <Col xs={12} sm={12} md={6} lg={6} className="col-fields">
          <label>Concentração (µg/mL)</label>
          <Input
            placeholder="ex.: 123 µg/mL (maior que zero)"
            minLength={2}
            name="concentracao_ml"
            regex={/^[+-]?\d+(\,\d+)?$/}
            mask={floatMask}
            effectFocus={false}
            borderColor="#B9B9B9"
            borderHighlightColor="#ED0F69"
          />
        </Col>
      </Row>

      <Row className="fields">
        <Col xs={12} sm={12} md={12} lg={12} className="col-fields centered">
          <button
            className="btn-outlined button-chart "
            onClick={props.addChartPoint}
            type="button"
          >
            Inserir ponto no gráfico
          </button>
        </Col>
      </Row>
    </>
  )
}

Fields.defaultProps = {
  addChartPoint: () => {},
  onAUCChange: () => {}
}

export default Fields
