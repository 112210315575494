import React, { useState, useRef } from 'react'

import { Grid, Row, Col } from 'atomic'

import { FormContainer, FormFields, GrayArea } from './styled'

import Spinner from './../assets/spinner.svg'

import Charts from './charts'

import Fields from './fields'

import axios from 'axios'

import html2canvas from 'html2canvas'

const Form = props => {
  const form = useRef()
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const [loadingChart, setLoadingChart] = useState(false)
  const [dataChart, setDataChart] = useState([[0, 0]])
  const [AUC, setAUC] = useState('')

  const defaultJson = {
    tempo_min: [],
    concentracao_ml: [],
    dose_inicial: 0,
    peso: 0,
    intervalo_entre_doses: 0,
    AUC_alvo: 0
  }

  const [json, setJson] = useState({ ...defaultJson })

  const getValues = () => {
    const formData = new FormData(form.current)
    let values = {}
    for (let pair of formData.entries()) {
      values[pair[0]] = parseFloat(pair[1].replace(',', '.'))
    }

    return values
  }

  const prepareJsonChart = () => {
    const { tempo_min, concentracao_ml } = getValues()

    return {
      tempo_min: [...json.tempo_min, tempo_min],
      concentracao_ml: [...json.concentracao_ml, concentracao_ml]
    }
  }

  const prepareJson = () => {
    const { dose_inicial, peso, AUC_alvo } = getValues()

    return {
      tempo_min: json.tempo_min,
      concentracao_ml: json.concentracao_ml,
      dose_inicial,
      peso,
      intervalo_entre_doses: 24,
      AUC_alvo
    }
  }

  const addChartPoint = async () => {
    const jsonPrepared = prepareJsonChart()

    const values = getValues()

    if ((!values.tempo_min, !values.concentracao_ml)) {
      return
    }

    const previous = json.tempo_min[json.tempo_min.length - 1]
    if (values.tempo_min < previous) {
      setMessage(
        `O tempo informado deve ser maior em relação ao tempo do ponto anterior. Tempo anterior: ${previous}`
      )
      return
    }

    setLoadingChart(true)

    const newJson = {
      ...json,
      ...jsonPrepared
    }

    setJson(newJson)

    setDataChart([...dataChart, [values.tempo_min, values.concentracao_ml]])

    const inputs = form.current.elements

    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].name === 'concentracao_ml' || inputs[i].name === 'tempo_min') {
        inputs[i].value = ''
        inputs[i].classList.remove('filled')
      }
    }

    try {
      const jsonPreparedToAPI = {
        ...prepareJson(),
        concentracao_ml: newJson.concentracao_ml,
        tempo_min: newJson.tempo_min
      }

      const last = jsonPreparedToAPI.concentracao_ml[jsonPreparedToAPI.concentracao_ml.length - 1]
      const penultimate =
        jsonPreparedToAPI.concentracao_ml[jsonPreparedToAPI.concentracao_ml.length - 2]

      if (last < penultimate) {
        const response = await getApiData(jsonPreparedToAPI)

        if (response.data.AUC_total) {
          setAUC(response.data.AUC_total)
        }
      }
    } catch (e) {
      //console.log(e)
    }

    setTimeout(() => {
      setLoadingChart(false)
    }, 100)
  }

  const limparDados = () => {
    window.location.reload()
  }

  const onSubmit = async e => {
    e.preventDefault()

    props.onResult({})

    const jsonPrepared = prepareJson()
    const last = jsonPrepared.concentracao_ml[jsonPrepared.concentracao_ml.length - 1]
    const penultimate = jsonPrepared.concentracao_ml[jsonPrepared.concentracao_ml.length - 2]

    if (!penultimate) {
      setMessage('Por favor adicione mais pontos ao gráfico.')
      return
    }

    if (!(last < penultimate)) {
      setMessage(
        `O último ponto do gráfico deve possuir uma concentração menor em relação ao penúltimo ponto. Concentração anterior: ${penultimate}`
      )
      return
    }

    setLoading(true)

    window.scroll({ top: 40, behavior: 'auto' })

    await new Promise(resolve => {
      setTimeout(() => {
        resolve()
      }, 1000)
    })

    const elementChart = document.getElementsByClassName('ReactChart')[0]
    html2canvas(elementChart, {
      logging: true,
      letterRendering: 1,
      allowTaint: false,
      useCORS: true,
      width: 800,
      height: 500
    }).then(canvasChart => {
      props.onChartImage(canvasChart.toDataURL())
    })

    setJson(jsonPrepared)

    setMessage('')

    try {
      const response = await getApiData(jsonPrepared)
      props.onResult({ ...response.data })

      const topAfterLoading = document.getElementById('result').offsetTop

      window.scroll({ top: topAfterLoading, behavior: 'smooth' })

      if (response.data.messages && response.data.messages.length > 0) {
        let message = ''

        response.data.messages.map((text, index) => {
          if (index > 0) {
            message += '\n \n'
          }
          message += text
          return ''
        })

        setMessage(message)
      }
    } catch (e) {
      setMessage('Ocorreu um erro ao calcular, por favor altere os parâmetros e tente novamente.')
    }

    setLoading(false)
  }

  const getApiData = async jsonPrepared => {
    const url = 'https://hjddqtc561.execute-api.us-east-1.amazonaws.com/prd/calculate'

    const response = await axios.post(url, jsonPrepared, {
      headers: {
        'content-type': 'application/json',
        'x-api-key': 'moaWgLb6rC7X8qFbRQYbv90uy6BJfw8l6D2FPzf9'
      }
    })

    if (typeof response.data === 'string') {
      var re = new RegExp('NaN', 'g')

      response.data = JSON.parse(response.data.replace(re, 'null'))
    }

    for (let key in response.data) {
      if (key !== 'messages') {
        response.data[key] = response.data[key].toString().replace('.', ',')
      }
    }

    return response
  }

  return (
    <FormContainer onSubmit={onSubmit} ref={form}>
      <Grid>
        <Row>
          <Col xs={12}>
            <Row className="row-with-legenda">
              <Col lg={6} md={12} xs={12}>
                <Row>
                  <Col id="form" clasName="form" xs={12} lg={12}>
                    <GrayArea>
                      <FormFields>
                        <Fields addChartPoint={addChartPoint} values={json} />
                      </FormFields>
                    </GrayArea>
                  </Col>
                </Row>
              </Col>

              <Col lg={6} md={12} xs={12}>
                <Row>
                  <Col lg={12} md={12} xs={12} className="chart-row">
                    <div className="legenda vertical">
                      <p>Concentração (µg/mL)</p>
                    </div>

                    <div>
                      <p className="subtitle-chart">
                        <strong>Tempo (min) vs. Concentração (µg/mL) </strong>
                        <br />
                        Com base nos dados inseridos
                      </p>

                      <p className="centered-subtitle">
                        {AUC
                          ? `Área sob a curva: ${AUC} µmol/L*min`
                          : `Área sob a curva: (Preencha o formulário e adicione pontos)`}
                      </p>
                    </div>

                    {!loadingChart && <Charts data={dataChart} />}
                    <div className="legenda">
                      <p>Tempo (min)</p>
                    </div>
                  </Col>
                </Row>

                <Row className="row-buttons">
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <button type="submit" className="btn-pink" disabled={dataChart.length < 2}>
                      Calcular dosagem
                    </button>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} mdOffset={0} lgOffset={0}>
                    <button className="btn-outlined" onClick={limparDados}>
                      Limpar dados
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>

      {message && (
        <Grid>
          <Row className={`message ${message.error ? 'error' : ''}`}>
            <Col xs={12} lg={12}>
              <p className="title">Mensagem do sistema:</p>
              <p>{message}</p>
            </Col>
          </Row>
        </Grid>
      )}

      {loading && (
        <div className="spiner-container">
          <img className="spiner" alt="spiner" src={Spinner} />
        </div>
      )}
    </FormContainer>
  )
}

Form.defaultProps = {
  onResult: () => {},
  onChartImage: () => {}
}
export default Form
