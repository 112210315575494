import styled from 'styled-components'

export const ResultsContainer = styled.div`
  padding-top: 50px;
  text-align: center;
  margin-bottom: 80px;
  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;

    color: #464646;
  }

  .ctn-results {
    display: flex;
    justify-content: center;

    .col-results {
      background: #f8f8f8;
      border-radius: 16px;
      margin-top: 25px;
      padding: 50px;

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;

        color: #77787b;

        strong {
          font-weight: 600;
          font-size: 18px;
          line-height: 18px;

          color: #77787b;
        }

        &.pink-highlight {
          font-weight: 600;
          font-size: 24px;
          line-height: 24px;
          margin-top: 20px;
          color: #ed0f69;
        }
      }

      hr {
        margin-top: 40px;
        margin-bottom: 40px;
        border: 1px solid #e0e0e0;
      }

      button {
        max-width: 300px;
      }
    }
  }

  .fields-result {
    display: flex;
    justify-content: center;

    .fields {
      text-align: left;
      label {
        font-size: 16px;
      }

      .field-checkbox {
        display: flex;
        justify-content: flex-start;
        .custom-checkbox {
          margin-left: 25px;
        }
        p {
          font-size: 16px;
          margin-left: 10px;
          width: 70%;
        }
      }
    }
  }

  .col-privacy {
    margin-top: 40px;
    a {
      color: #ed0f69;
    }
  }

  @media (max-width: 1200px) {
    .ctn-results {
      .col-results {
        padding-left: 20px;
        padding-right: 20px;
        button {
          width: 100%;
        }
      }
    }
  }

  @media print {
    @page {
      size: A4 portrait;
    }
    display: none;
  }
`
